import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import { ExternalLink, Image, ImageGroup } from '~/elements/mdx'
import { Link } from 'gatsby'

const components = { ExternalLink, Image, ImageGroup, Link }

interface Props {
  children: string // Contentful Text response
}

const Markdown: React.FC<Props> = ({ children }) => {
  return (
    <MDXProvider components={components}>
      <MDXRenderer>{children}</MDXRenderer>
    </MDXProvider>
  )
}

export default Markdown
