import { ReactElement, ReactNode } from 'react'
import CloudinaryImage, {
  Props as CloudinaryImageProps,
} from '~/components/cloudinary-image'

interface ExternalLinkProps {
  href: string
  children: ReactNode
}

export const ExternalLink = ({
  href,
  children,
}: ExternalLinkProps): ReactElement => (
  <a href={href} target="_blank" rel="noopener">
    {children}
  </a>
)

interface ImageProps extends CloudinaryImageProps {
  noborder?: boolean
}

export const Image = ({
  url,
  width,
  height,
  noborder,
}: ImageProps): ReactElement => {
  let className = `mdx-image`
  if (noborder) {
    className += ` bare`
  }

  return (
    <div className={className}>
      <CloudinaryImage url={url} width={width} height={height} />
    </div>
  )
}

interface ImageGroupProps {
  group: ImageProps[]
}

export const ImageGroup = ({ group }: ImageGroupProps): ReactElement => {
  return (
    <div className="mdx-image-group">
      {group.map((image, idx) => {
        const { url, width, height } = image
        return (
          <div key={idx}>
            <CloudinaryImage url={url} width={width} height={height} />
          </div>
        )
      })}
    </div>
  )
}
