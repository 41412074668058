import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import { useIsClient } from '~/hooks/use-is-client'
import ArticleCard from '~/components/article-card'

const N_MAX_ARTICLES = 2

interface Article {
  title: string
  slug: string
  description: string
  featureImage: {
    secure_url: string
    height: number
    width: number
  }[]
  publishedAt: string
  tags: { title: string }[]
}

interface Tag {
  slug: string
  title: string
  article: Article[]
}

interface Props {
  slug: string
  tags: Tag[]
}

type ArticleMapType = { [title: string]: Article[] }

const TagRelatedArticles: React.FC<Props> = ({ slug, tags }) => {
  /**
   * NOTE: This is for when there is no related articles.
   *       We can write useStaticQuery inside `if (articles.length === 0) {}`,
   *       but it might decrease readability...
   */
  const { contentfulArticle } = useStaticQuery(
    graphql`
      query {
        contentfulArticle(slug: { eq: "yokohama-baycourt-202209" }) {
          title
          slug
          description
          featureImage {
            secure_url
            height
            width
          }
          publishedAt(formatString: "YYYY年M月D日")
          tags {
            title
          }
        }
      }
    `
  )

  const { isClient } = useIsClient()

  const title2Articles = tags.reduce((map, tag) => {
    map[tag.title] = tag.article.reduce((arr, el) => {
      // Do not include self
      if (el.slug !== slug) {
        arr.push(el)
      }
      return arr
    }, [] as Article[])

    return map
  }, {} as ArticleMapType)

  const included: { [slug: string]: boolean } = {}

  const articles = Object.keys(title2Articles).reduce((arr, title) => {
    if (arr.length == N_MAX_ARTICLES) {
      return arr
    }

    const candidates = title2Articles[title]

    if (candidates.length !== 0) {
      const rnd = Math.floor(Math.random() * candidates.length)
      const candidate = candidates[rnd]

      if (!included[candidate.slug]) {
        included[candidate.slug] = true
        arr.push(candidate)
      }
    }

    return arr
  }, [] as Article[])

  if (articles.length === 0) {
    articles.push(contentfulArticle)
  }

  if (!isClient) return null
  return (
    <>
      {articles.map((article, idx) => {
        const { title, slug, description, featureImage, publishedAt, tags } =
          article
        const thumbnail = featureImage[0]
        const tagValues = tags.map((tag) => tag.title)
        return (
          <ArticleCard
            key={idx}
            title={title!}
            slug={slug!}
            description={description}
            publishedAt={publishedAt}
            tags={tagValues}
            thumbnail={{
              url: thumbnail.secure_url!,
              width: thumbnail.width!,
              height: thumbnail.height!,
            }}
          />
        )
      })}
    </>
  )
}

export default TagRelatedArticles
